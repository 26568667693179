import React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/Page';
import PostsByDate from '../components/PostsByDate';

function Archives({ data }) {
  const posts = data.allMarkdownRemark.edges;

  const postsByDate = posts.reduce((acc, cur) => {
    const {
      fields: { slug },
      frontmatter: { title, date: fullDate },
    } = cur.node;

    const [dateGroup, dayGroup, dateSlug] = (fullDate &&
      fullDate.split(', ')) || ['', ''];
    const [day] = dayGroup.split('-');
    const accDateGroupIndex = acc.findIndex(
      group => group.dateGroup === dateGroup
    );
    const post = {
      title,
      slug,
      day,
    };

    let addDateGroup;
    if (accDateGroupIndex > -1) {
      acc[accDateGroupIndex].posts = {
        ...acc[accDateGroupIndex].posts,
        [dayGroup]: post,
      };
    } else {
      addDateGroup = {
        dateGroup,
        dateSlug: `/blog/${dateSlug}`,
        posts: {
          [day]: post,
        },
      };
    }

    return !!addDateGroup ? [...acc, addDateGroup] : acc;
  }, []);

  return (
    <Page title="Archives" slug="/archives">
      {postsByDate.map(dateGrouping => {
        return (
          <div key={dateGrouping.dateGroup}>
            <PostsByDate
              date={dateGrouping.dateGroup}
              dateSlug={dateGrouping.dateSlug}
              posts={dateGrouping.posts}
            />
          </div>
        );
      })}
    </Page>
  );
}

export default Archives;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM YYYY, DD-HH, YYYY/MM")
            title
          }
        }
      }
    }
  }
`;
