import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styles from './styles.module.scss';

function PostsByDate({ date, dateSlug, posts }) {
  return (
    <div className={styles.postsByDate}>
      <h4 className={styles.dateHeading}>
        <Link to={dateSlug}>{date}</Link>
      </h4>
      <ul className={styles.posts}>
        {Object.values(posts).map(({ day, title, slug }) => {
          return (
            <li key={title}>
              {day}: <Link to={`/blog${slug}`}>{title}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

PostsByDate.propTypes = {
  date: PropTypes.string.isRequired,
  dateSlug: PropTypes.string.isRequired,
  posts: PropTypes.shape({
    day: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default PostsByDate;
